@use 'bootstrap/scss/bootstrap' as *;
@use '@angular/material/prebuilt-themes/indigo-pink.css' as *;
@use '@sweetalert2/theme-bootstrap-4/bootstrap-4.min.css' as *;
@use "sass:map";

$primary: #007dff;

* {
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, html {
  height: 100%;
  margin: 0;
}

body {
  background-color: #fafafa !important;
}

*:focus {
  outline: none !important;
}

.btn-block+.btn-block {
  margin-top: 0.5rem;
}

.fs-44px {
  font-size: 44px;
}


.notify {
  font-family: Roboto, sans-serif !important;
}

.fixed-overlay {
  background: linear-gradient(rgba(255, 255, 255, 0) 2.5%, rgba(255, 255, 255, 0.92) 55.35%);
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  transition: all 400ms ease;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
}

.image-cover {
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.responsive {
  position: relative;
  overflow: hidden;
  flex: 1 0 auto;
  max-width: 100%;
  display: flex;
}

.responsive-sizer {
  transition: padding-bottom 0.2s cubic-bezier(0.25, 0.8, 0.5, 1);
  flex: 1 0 0px;
}

.responsive-content {
  margin-left: -100%;
  flex: 1 0 0px;
  max-width: 100%;
}

.attributi .badge {
  font-size: 70%;
  text-transform: lowercase;
}

.attributi .badge::first-letter {
  text-transform: uppercase;
}
.btn-content {
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1 0 auto;
  justify-content: inherit;
  line-height: normal;
  position: relative;
  transition: inherit;
}

.btn-round {
  border-radius: 50% !important;
}

.align-center {
  align-items: center !important;
}

.mat-success > div{
  background-color: #4caf50 !important;
  .mdc-button {
    color: #fff !important;
  }
}

.mat-error > div {
  background: #b71c1c;
  .mdc-button {
    color: #fff !important;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.modal-contatti .close {
  color: red;
  opacity: 1;
  text-shadow: none;
}

.modal-contatti .modal-header {
  border: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

#toast-container.toast-bottom-full-width .toast-cart {
  margin-bottom: 65px;
}

.input-group-text {
  font-weight: 900;
}

.modal-content {
  border: 0;
}

.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  z-index: 3;
  top: 0;
}

.qrcode {
  align-items: center;
  justify-content: center;
  opacity: 0.8;
}

.accordion > .card > .card-header {
  margin-bottom: 0 !important;
  padding: 0 !important;
}

.accordion > .card {
  border-radius: 0;
  border: none;
}

.aggiunte-title {
  padding: 0.5rem 1rem 0.5rem 1rem;
}

.indeterminate .progress .progress-bar {
  position: relative;
  animation: progress-indeterminate 2s linear infinite;
}

.indeterminate .progress {
  position: sticky;
  top: 0;
  z-index: 10;
  border-radius: 0;
  background-color: #7dbeff;
}

select.form-control {
  border: 0;
  min-height: 48px;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),
    0 1px 5px 0 rgb(0 0 0 / 12%);
}

select.form-control:focus {
  border-color: rgb(206, 212, 218);
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),
    0 1px 5px 0 rgb(0 0 0 / 12%);
}

select {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
}

.dropdown-menu {
  box-shadow: 0 0 0 1px #0000330f,0 12px 60px #0000330f,0 12px 32px -16px #0009321f !important;
}

@keyframes progress-indeterminate {
  from {
    left: -25%;
    width: 25%;
  }
  to {
    left: 100%;
    width: 25%;
  }
}

.accordion-toggle {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding: 0;
  width: 100%;
  display: block;
  border: 0;
  text-align: start;
}

.accordion-toggle.collapsed {
  background: #ffffff;
  color: rgb(56, 56, 56);
}

.accordion-toggle {
  background: #ffffff;
  color: rgb(0, 110, 255);
}

.aggiunte-title strong {
  margin-bottom: 0;
}

.aggiunte-title::after {
  display: inline-block;
  position: absolute;
  top: 36px;
  right: 10px;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.btn-carret::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.pwa-ios {
  .mat-bottom-sheet-container {
    padding: 0;
    border-top-left-radius: 0.7rem !important;
    border-top-right-radius: 0.7rem !important;
  }
}

.pwa-android {
  padding: 0 !important;
}

.pwa-android mat-toolbar {
  background: #0078ff;
}

.row--dense {
  margin: -4px;
}

.row--dense > .col,
.row--dense > [class*="col-"] {
  padding: 4px;
}

.flex-1 {
  flex: 1;
}

.card .product {
  color: rgba(0, 0, 0, 0.6);
}

.modal-fullscreen-sm {
  transform-origin: center center;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
}

.light-backdrop-sm {
  transition: .3s cubic-bezier(.25,.8,.5,1),z-index 1ms !important;
}

@mixin modal-fullscreen() {
  .modal-dialog {
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    margin: 0;
  }

  .modal-dialog > .modal-content {
    display: flex;
    flex: 1 1 100%;
    flex-direction: column;
    max-height: 100%;
    max-width: 100%;
  }

  .component-host-scrollable {
    height: 100%;
  }

  .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
  }
}

.modal-backdrop.fade {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), z-index 1ms;
}

.modal-header {
  border-bottom: 0 !important;
}

.modal-header .modal-title {
  color: rgba(0, 0, 0, 0.87);
}

.flex {
  flex: 1 1 0%;
}

.rounded-lg {
  border-radius: 8px !important;
}

@mixin light-backdrop() {
  background-color: #ffffff;
  opacity: 1;
}

@each $breakpoint in map.keys($grid-breakpoints) {
  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
  @include media-breakpoint-down($breakpoint) {
    .modal-fullscreen#{$infix} {
      @include light-backdrop();
    }

    .modal-fullscreen#{$infix} {
      @include modal-fullscreen();
    }

    .btn#{$infix}-block {
      display: block;
      width: 100%;
    }
  }
}
